import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Avatar from "../components/avatar"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Lars Wächter"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Avatar />
        <h1>
          Hello there{" "}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </h1>
        <p>Glad you found me!</p>
        <p>
          My name is Lars. I'm a cs student and software engineer from Germany
          who likes to share his thoughts and knowledge on the internet.
        </p>
        <p>
          Usually I write about computer science stuff like software
          development, artificial intelligence or other trending technologies.
        </p>
        <p>
          Checkout my <Link to="/blog/">blog</Link> or{" "}
          <Link to="https://book.larswaechter.dev">dev handbook</Link> and feel free to stay in
          contact with me:
        </p>
        <ul style={{ paddingLeft: "25px" }}>
          <li>
            <Link
              to="https://www.linkedin.com/in/lars-w%C3%A4chter-3a85251a3/"
              target="_blank"
            >
              LinkedIn
            </Link>
          </li>
          <li>
            <Link to="https://github.com/larswaechter/" target="_blank">
              GitHub
            </Link>
          </li>
          <li>
            <Link to="https://medium.com/@larswaechter" target="_blank">
              Medium
            </Link>
          </li>
          <li>
            <Link
              to="https://www.buymeacoffee.com/larswaechter"
              target="_blank"
            >
              Buy me a coffee
            </Link>
          </li>
        </ul>
        <p>Greetz Lars</p>
        <Link to="/blog/">
          <Button marginTop="35px">My Blog</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage
