import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Avatar = () => {
  const data = useStaticQuery(graphql`
    query {
      file(absolutePath: { regex: "/avatar.jpg/" }) {
        childImageSharp {
          fixed(width: 130, height: 130) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img
      style={{ borderRadius: "50%", float: "right" }}
      fixed={data.file.childImageSharp.fixed}
    />
  )
}

export default Avatar
